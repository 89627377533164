import React, { lazy, Suspense } from "react";
import { Outlet, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import { SC_Roles, SC_Routes } from "./containers/Data";
import { LoaderComponent } from "./components/CustomizedPageActions";
const NotFound = lazy(() => import("./containers/NotFound"));
const Home = lazy(() => import("./containers/Home"));
const Login = lazy(() => import("./containers/Login"));
const FreeTrialSignUp = lazy(() => import("./containers/FreeTrialSignup"));
const UserAccounts = lazy(() => import("./containers/UserAccounts"));
const Transactions = lazy(() => import("./containers/Transactions"));
const Swagger = lazy(() => import("./containers/Swagger"));
const QuickStartTrial = lazy(() => import("./containers/QuickStartTrial"));
const Clients = lazy(() => import('./containers/Clients'));

export default function AppRoutes({ appProps }) {
  const SE_Env =
    process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "SE";
  const location = useLocation();
  const navigate = useNavigate();

  function EmptyContainerLoader() {
    return (
      <div className="empty-loading-container" id="empty-container">
        {LoaderComponent("empty-container")}
      </div>
    );
  }

  // Add a condition to check if the user is authorized
  const isAuthorized = appProps.isAuthenticated;

  // If the user is not authorized and not on the login page, redirect to the login page
  if (!isAuthorized && location.pathname !== "/login") {
    navigate("/login");
  }

  return (
    <Suspense fallback={<EmptyContainerLoader />}>
      <Outlet />
      <Routes>

        {/* UnauthenticatedRoute */}
        <Route
          path="/login"
          element={
            appProps.isAuthenticated ? (
              <Navigate to="/" replace />
            ) : (
              <Login {...appProps} />
            )
          }
        />

        {/* AuthenticatedRoute */}
        <Route
          path="/"
          element={
            appProps.isAuthenticated ? (
              SE_Env &&
                appProps.role === SC_Roles.QuickStart &&
                appProps.trialVars.trialInProgress ? (
                <QuickStartTrial {...appProps} />
              ) : (
                <Home {...appProps} />
              )
            ) : (
              <Navigate
                to={`/login?redirect=${location.pathname}${location.search}`}
                replace
              />
            )
          }
        />

        {SE_Env && (
          <Route
            path={SC_Routes.FreeTrialSignup}
            element={<FreeTrialSignUp {...appProps} />}
          />
        )}

        <Route path={SC_Routes.Dashboards} element={<Home {...appProps} />} />

        <Route path={SC_Routes.Clients} element={<Clients {...appProps} />} />

        {appProps.role !== SC_Roles.QuickStart && (
          <Route
            path={SC_Routes.UserAcc}
            element={<UserAccounts {...appProps} />}
          />
        )}

        <Route path={SC_Routes.Transactions} element={<Transactions {...appProps} />} />

        <Route path={SC_Routes.Swagger} element={<Swagger {...appProps} />} />

        {SE_Env && (appProps.role === SC_Roles.QuickStart || appProps.role === "") && (
          <Route
            path={SC_Routes.QuickStart}
            element={<QuickStartTrial {...appProps} />}
          />
        )}

        {/* Finally, catch all unmatched routes */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
